<template>
  <div class="modal-courses-list">
    <b-modal
      centered
      id="modal-courses-list"
      scrollable
      ok-variant="primary"
      cancelVariant="secondary"
      :ok-disabled="loading || !courses.length"
      :no-close-on-esc="loading"
      :no-close-on-backdrop="loading"
      @ok="handleOk"
      @show="getCourses"
      @hidden="resetSelectedCourses"
    >
      <template #modal-title>
        <div class="modal-courses-list__title">{{ $t('header.addCourseToProgram') }}</div>
        <div
          v-if="!loading"
          class="modal-courses-list__subtitle"
          :class="{ 'max-width-none': !courses.length }"
        >
          {{
            courses.length
              ? $t('supportText.selectACourseFromTheList')
              : $t('supportText.notFoundCoursesToAdd')
          }}
        </div>
      </template>
      <template #modal-header-close>
        <SmallCloseButton />
      </template>
      <template #default>
        <transition name="component-fade" mode="out-in">
          <div key="content" v-if="courses.length" class="modal-courses-list__content">
            <CourseTile
              v-for="course of courses"
              :course="course"
              :key="course._id"
              :isSelected="isSelected(course._id)"
              @toggleSelect="toggleSelect(course._id)"
            />
          </div>
          <div key="loader" v-else class="empty-state">
            <div class="empty-state__title">
              <span v-if="loadingCourses" class="f-center">
                <Loader />
              </span>
              <span v-else>{{ $t('supportText.createNewCourse') }}</span>
            </div>
          </div>
        </transition>
      </template>
      <template #modal-ok
        ><span v-if="!loading">{{ $t('buttonLabels.addCourses') }}</span>
        <span v-else>
          <b-spinner small type="grow"></b-spinner>
          {{ $t('label.processing') }}
        </span></template
      >
      <template #modal-cancel>{{ $t('buttonLabels.cancel') }}</template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import CourseTile from '@/components/Cards/CourseTile.vue';
import ActivityService from '@/services/activity.service';
import SmallCloseButton from '@/components/Buttons/SmallCloseButton.vue';
import Loader from '@/components/Loader/Loader.vue';

export default {
  name: 'ModalCoursesList',
  components: {
    Loader,
    CourseTile,
    SmallCloseButton,
  },
  props: {
    programCoursesList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      courses: [],
      selected: [],
      loading: false,
      loadingCourses: true,
      parentId: this.$route.params.programId,
    };
  },
  computed: {
    isCourseLimitExceeded() {
      return this.programCoursesList.length + this.selected.length >= 15;
    },
  },
  methods: {
    ...mapActions('toaster', ['setToaster']),
    toggleSelect(id) {
      const foundIndexCourse = this.courses.findIndex((i) => i._id === id);
      const indexSelected = this.selected.findIndex((i) => i._id === id);
      if (indexSelected === -1) {
        if (this.isCourseLimitExceeded) {
          return this.setToaster({
            type: 'toast-warning',
            toast: {
              title: this.$t('supportText.youCannotCreateMore'),
              body: this.$t('supportText.exceededLimit'),
            },
          });
        }
        this.selected.push(this.courses[foundIndexCourse]);
      } else {
        this.selected.splice(indexSelected, 1);
      }
    },
    isSelected(id) {
      return !!this.selected.filter((i) => i._id === id).length;
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      if (!this.selected.length) {
        return;
      }
      this.addCourses();
    },
    async addCourses() {
      try {
        this.loading = true;
        await ActivityService.addActivityParent({
          programId: this.$route.params.programId,
          ids: this.selected.map((s) => s._id),
          parentId: this.parentId,
        });
        this.$emit('add-courses', this.selected);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
      this.$bvModal.hide('modal-courses-list');
    },
    filterCoursesFromAlreadyIncluded({ data }) {
      this.courses = data.filter(
        (course) => this.programCoursesList.findIndex((i) => i._id === course._id) === -1,
      );
    },
    getCourses() {
      this.loadingCourses = true;
      ActivityService.getCourses()
        .then(this.filterCoursesFromAlreadyIncluded)
        .catch(console.log)
        .finally(() => (this.loadingCourses = false));
    },
    resetSelectedCourses() {
      this.selected = [];
    },
  },
};
</script>
